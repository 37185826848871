<template>
  <div id="app">
    <h1 style="position: absolute; color:#fff;">优加惠品</h1>
    <!-- 顶部banner区域 -->
    <pubHeader :fooPath="fooPath"></pubHeader>
    <router-view></router-view>
    <pubFooter @fooNav="fooNav"></pubFooter>
  </div>
</template>

<script>
import home from './views/Home.vue'
import pubHeader from '@/components/pubHeader.vue'
import pubFooter from '@/components/pubFooter.vue'
import gdmap from '@/components/gdmap.vue'
export default {
  name: 'app',
  components: {
    home,
    pubHeader,
    pubFooter,
    gdmap
  },
  created() {

  },
  data() {
    return {
      fooPath:''
    }
  },
  methods:{
    fooNav(val) {
      this.fooPath = val;
    }
  }
}
</script>

<style>
#app {
  min-width: 1400px;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a {
  text-decoration: none;
  color: #fff;
}
.selImg{
  -webkit-filter:grayscale(100%);
  filter: gray !important;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.swiper {
  /*height: 524px;*/
}
.swiper img{
  width: 100%;
  /*height: 524px;*/
  /*object-fit: inherit;*/
}

</style>
