<template>
  <div id="amapcontainer" style="width: 100%; height: 684px"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
window._AMapSecurityConfig = {
  securityJsCode: '745b2d9c03b56b0409bc97640a3dac20' // '「申请的安全密钥」',
}
export default {
  data () {
    return {
      map: null
    }
  },
  created() {
  },
  methods: {

    initAMap () {

      // 将 markers 添加到地图
      AMapLoader.reset();
      AMapLoader.load({
        key: "e8088bf0c67118235fa23a31ce9b8c99", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
          'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        // 获取到作为地图容器的DOM元素，创建地图实例
        this.map = new AMap.Map("amapcontainer", { //设置地图容器id
          resizeEnable: true,
          // zoom: this.zoom, // 地图显示的缩放级别
          viewMode: "3D", // 使用3D视图
          zoomEnable: true, // 地图是否可缩放，默认值为true
          dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
          doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
          zoom: 18, //初始化地图级别
          center: [116.509034,39.911463], // 初始化中心点坐标
        })
        var markerContent = '' +
            '<div class="custom-content-marker">' +
            '   <img src="https://qiniu.youjiamall.cn/yjpc/map_icon.png">' +
            '</div>';
        let marker = new AMap.Marker({
          map: this.map,
          content:markerContent,
          position: [116.509034,39.911463]
        })
        map.add(marker)
      }).catch(e => {
        console.log(e)
      })
    }
  },
  mounted () {
    //DOM初始化完成进行地图初始化
    this.initAMap()
  }
}
</script>

<style>
.custom-content-marker img {
  width: 16px;
  height: 20px;
}
</style>
