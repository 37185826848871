<template>
    <header class="top">

        <div class="top_nav">
            <div class="logo_img">
              <img src="@/assets/img/logo.png" alt="">
            </div>
            <ul class="nav_list">
                <li v-for="(item, index) in navList" @mousemove="mouseChange(index)" @mouseleave="mouseChange(-1)" @click="navClick(item, index)" :class="{ navActive: current == index || isChildShow && item.name == '企业福利'}"
                    :key="index">
                        {{ item.name }}
                        <img v-if="item.name == '企业福利'" src="@/assets/img/down_icon.png" alt="">
                        <ul class="nav_child_list" v-if="isChildShow && index == 1">
                            <li :class="{ navActive: sonCurrent == index }"
                                @click.stop="sonClick(son,index)"
                                v-for="(son, index) in navChild"
                                :key="index">
                                {{son.name}}

                            </li>
                        </ul>
                </li>
            </ul>

        </div>
        <div class="banner">
<!--          <pubBanners></pubBanners>-->
        </div>
    </header>
</template>
<script>
import pubSwiper from './pubSwiper.vue'
import pubBanners from './pubBanners.vue'
export default {
  props:{
    fooPath:{
      type:String,
      default:""
    }
  },
    components: { pubSwiper,pubBanners },
    created() {
      if(sessionStorage.getItem("current")) {
        this.current = sessionStorage.getItem("current");//获取名称为“key”的值
      }
    },
    data() {
        return {
            navList: [{
                name: '首页',
                path:'/'
            }, {
                name: '企业福利',
                path:'/',

            }, {
                name: '企业活动',
                path:'/activity'
            }, {
                name: '品牌介绍',
                path:'/brandInt',
            }, {
                name: '联系我们',
                path:'/aboutUs'
            }],
          navChild:[{
            name:'电影福利',
            path:'/welfare'
          },{
            name:'蛋糕福利',
            path:'/cake'
          },{
            name:'图书福利',
            path:'/book'
          },{
            name:'节日慰问',
            path:'/holiday'
          }],
            current: 0,
            sonCurrent: -1,
            isChildShow: false
        }
    },
    watch:{

      fooPath(newVal,oldVal){
        switch (newVal) {
          case '/activity':
            this.current = 2;
            break;
          case '/brandInt':
            this.current = 3;
            break;
          case '/aboutUs':
            this.current = 4;
            break;
          case '/holiday':
            this.sonCurrent = 0;
            this.current = 1;
            break;
          case '/cake':
            this.sonCurrent = 1;
            this.current = 1;
            break;
          case '/welfare':
            this.sonCurrent = 3;
            this.current = 1;
            break;
          case '/book':
            this.sonCurrent = 2;
            this.current = 1;
            break;
        }
      }
    },
    methods: {
        mouseChange(index) {
          if( index== 1) {
            this.isChildShow = true;
          }
          if(index == -1) {
            this.isChildShow = false;
          }
        },
        navClick(item, index) {
          if(item.name == '企业福利') {
            return false;
          }
          if(this.current == 1 && index == 1) {
            this.isChildShow = true;
            this.this.navList[1].path = this.fooPath;
            return false;
          }
          if(index != 1) {
            this.isChildShow =false;
          }
            this.current = index;
            sessionStorage.setItem("current",index);

          if(item.name == '企业福利' && index == 1) {
                this.isChildShow = !this.isChildShow;
            }

          this.sonCurrent = -1
            this.$router.push({
                path:item.path
            })
        },
        sonClick(item,index) {
          this.current = 1;
          sessionStorage.setItem("current",this.current);
          this.navList[1].path = item.path;
            this.sonCurrent = index;
            this.isChildShow = false;
            this.$router.push({
              path:item.path
            })
        }
    }
}
</script>

<style scoped>
.top_nav {
    z-index: 9;
    position: relative;
    display: flex;
}

.nav_list {
    position: absolute;
    right: 120px;
    top: 20px;
    display: flex;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333;
}
a {
  color: #333;
}
.nav_list li {
    cursor: pointer;
    width: 82px;
    height: 30px;
    margin-right: 35px;
    text-align: center;
}
.nav_list li:nth-child(2):hover {

}
.nav_list img {
    width: 12px;
    height: 10px;
}

.nav_child_list {
    color: #333;
    margin-top: 15px;
    width: 82px;
    background: #fff;
    padding: 20px 0 5px;
    text-align: center;
    box-sizing: border-box;
  font-size: 12px;
}

.nav_child_list li {
    border: 0 !important;
}
.nav_child_list li:hover {
  color: #007858;
}
.navActive {
    border-bottom: 1.8px solid #007858;
  color: #007858;
}
.navActive a {
  color: #007858;
}
.logo_img {
    position: absolute;
    left: 142px;
    top: 18px;
}

.logo_img img {
    width: 40px;
    height: 40px;
}
</style>
