<template>
  <div class="we_prod">
      <div class="prod_wrap">
          <ul>
              <li v-for="(item, index) in prodList" :key="index" @mousemove="mouseover(index)" :class="{active_li:current == index}" :style="{  'background': 'url(' + (current === index ? item.icon_yes : item.icon_none) + ')' }">
                 <div style="width:100%;height:100%;background:#006c4f;border-radius:10px;position: relative" v-show="current == index">
                    <div>
                      <img class="pro-img" :src="item.icon_yes" style="position: absolute;right: 0;bottom: 0;" />
                      <div class="pro-text">
                        <p class="prd_title">{{ item.pr_name }}</p>
                        <p style="width: 70px;border-radius: 10px;background: #fff;height: 6px;margin-top: 10px;margin-bottom: 40px;"></p>
                        <p class="prd_prtop">{{ item.pr_ttop }}</p>
                        <p>{{ item.pr_tbtm }}</p>
                      </div>
                    </div>
                 </div>
                  <div v-show="current != index">{{ item.pr_name }}</div>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
          prodList: [{
            pr_name: '优加电影',
            pr_ttop: '10000+影院',
            pr_tbtm: '支持线上线下购票',
            icon_yes:'https://qiniu.youjiamall.cn/xfw1.png',
            icon_none:'https://qiniu.youjiamall.cn/xbkhm1.png',
          }, {
              pr_name: '优加蛋糕',
              pr_ttop: '600+品牌 全国配送',
              pr_tbtm: '线下直刷',
              icon_yes:'https://qiniu.youjiamall.cn/xfw2.png',
              icon_none:'https://qiniu.youjiamall.cn/xbkhm2.png',
          }, {
              pr_name: '优加礼包 ',
              pr_ttop: '主流品牌 量身定制',
              pr_tbtm: '全国免费包邮',
              icon_yes:'https://qiniu.youjiamall.cn/xfw3.png',
              icon_none:'https://qiniu.youjiamall.cn/xbkhm3.png',
          }, {
              pr_name: '优加演出',
              pr_ttop: '支持全国线上选座',
              pr_tbtm: '涵盖多种取票方式',
              icon_yes:'https://qiniu.youjiamall.cn/yanchu-tu.png',
              icon_none:'https://qiniu.youjiamall.cn/xbkhm4.png',
          },{
              pr_name: '优加图书',
              pr_ttop: '100W+图书资源',
              pr_tbtm: '优质的图书采购平台！',
              icon_yes:'https://qiniu.youjiamall.cn/xfw5.png',
              icon_none:'https://qiniu.youjiamall.cn/xbkhm5.png',
          }, {
              pr_name: '优加商城', 
              pr_ttop: '10W+优质商品',
              pr_tbtm: '随心选择',
              icon_yes:'https://qiniu.youjiamall.cn/xfw6.png',
              icon_none:'https://qiniu.youjiamall.cn/hmpr6.png',
          }, {
              pr_name: '优加生活',
              pr_ttop: '吃 喝 玩 乐',
              pr_tbtm: '应有尽有',
              icon_yes:'https://qiniu.youjiamall.cn/xfw7.png',
              icon_none:'https://qiniu.youjiamall.cn/hmpr7.png',
          },],
        current:0
      };
  },

  methods: {
    mouseover(index) {
      // console.log(index,'de')
      this.current = index;
    }
  }
}

</script>
<style scoped>
.we_prod {
    width: 100%;
    height: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    display: flex;
}
  .prod_wrap ul {
    display: flex;
    margin: 80px 0;
  }
  .prod_wrap li {
    width: 128px;
    height: 352px;
    background-size: 100% 100% !important;
    margin-right: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #007858;
  }

  .prod_wrap .active_li {
    width: 356px;
    height: 352px;
    background-size: 100% 100% !important;
    border-radius: 10px;
    color: #fff;
  }
  .prod_wrap div{
    padding-top: 40px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .pro-img {
    width: 270px;
    height: 270px;
  }
  .pro-text {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 10px;
    top: 0px;
  }
</style>
