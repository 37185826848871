<template>
    <div class="ser_wrap">
        <div class="ser_left">
            <ul>
                <li :class="{activeText:current == index}" @mousemove="navClick(index)" @click="opeClick(index)" v-for="(item, index) in leftArr" :key="index">{{ item }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            leftArr: ["电影福利", '蛋糕福利',"年节慰问","图书福利"],
            current:0
        };
    },

    methods: {
        navClick(index) {
            this.current = index;
            this.$emit('putUrl',index)
        },
        opeClick(index) {
          let url;
          switch (index) {
            case 0:
              url = '/welfare'
                  break
            case 1:
              url = '/cake'
                  break
            case 2:
              url = '/holiday'
                  break
            case 3:
              url = '/book'
                  break
          }
          this.$router.push(url);
          this.$parent.sonChange(url)
          sessionStorage.setItem("current",1);
        }
    }
}

</script>
<style scoped>
.ser_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 122px;
    box-sizing: border-box;
}

.ser_left {
    padding: 148px 0 160px;
    text-align: center;
    width: 153px;
    height: 600px;
    background: rgba(245, 215, 161, .85);
    border-top: 2px solid #007858;
    box-sizing: border-box;
    z-index: 9;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
}
.ser_left {
    display: flex;
    justify-content: center;
}
.ser_left li {
    cursor: pointer;
    width: 80px;
    margin-bottom: 57px;
    padding-bottom: 36px;
    border-bottom: 3.6px solid rgba(0,0,0,0) ;
    box-sizing: border-box;
}
.activeText {
    color: #007858;
    padding-bottom: 36px;
    box-sizing: border-box;
    border-bottom: 3.6px solid #007858 !important;
}
</style>
