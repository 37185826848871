import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=> import('@/views/Home.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: ()=> import('@/views/Activity.vue')
  },
  {
    path: '/holiday',
    name: 'Holiday',
    component: ()=> import('@/views/Holiday.vue')
  },
  {
    path: '/cake',
    name: 'Cake',
    component: ()=> import('@/views/Cake.vue')
  },
  {
    path: '/book',
    name: 'Book',
    component: ()=> import('@/views/Book.vue')
  },
  
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: ()=> import('@/views/aboutUs.vue')
  },
  {
    path: '/brandInt',
    name: 'BrandInt',
    component: ()=> import('@/views/BrandInt.vue')
  },
  {
    path: '/welfare',
    name: 'Welfare',
    component: ()=> import('@/views/Welfare.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
