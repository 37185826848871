<template>
    <div class="home">
      <h1 style="position: absolute; color:#fff;">优加惠品</h1>
      <!-- banner-->
      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <img  src="https://qiniu.youjiamall.cn/hm-banner.png" alt="">
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
        <!-- 模块区域 -->
        <main>
            <!-- 我们的产品 -->
            <div class="main_prod">
               <pubBoxTit title="我们的服务" ltit="SERVICE" paddingTop="55"></pubBoxTit>
                <div class="prod_mai weProd">
                    <weProd></weProd>
                </div>
            </div>

            <!-- 全方位定制服务 -->
            <div class="main_prod bgred" :style="'background:url('+bgurl+')no-repeat;'+ 'background-size: 100% 100%;'" style="">
                <pubBoxTit title="我们的产品" type="1" ltit="PRODUCT" paddingTop="35" ></pubBoxTit>
                <div class="prod_mai" style="position: relative">
                    <cuSerive @putUrl="putUrl"></cuSerive>
                    <div class="yjbgMini" v-if="current === 0">
                      <div class="min_dy">
                        <img src="@/assets/img/min_dy.png" alt="">
                      </div>
                      <div class="mi_left">
                        <img src="https://qiniu.youjiamall.cn/dy_card222.png" alt="">
                        <img src="https://qiniu.youjiamall.cn/dy_card333.png" alt="">
                      </div>
                      <div class="mi_right">
                        <img src="https://qiniu.youjiamall.cn/dy_card444.png" alt="">
                        <img src="https://qiniu.youjiamall.cn/dy_card555.png" alt="">
                        <img src="https://qiniu.youjiamall.cn/dy_card111.png" alt="">
                      </div>
                    </div>
                  <div v-if="current == 1" class="dg_mini">
                    <img src="@/assets/img/dg_mini.png" alt="">
                    <img src="https://qiniu.youjiamall.cn/yjpc/cake_card.png" alt="">
                  </div>

                  <div v-if="current == 3" class=" book_mini">
                    <img class="book-tips" src="https://qiniu.youjiamall.cn/book-tips1.png" alt="">
                    <!-- <img class="book-img" src="https://qiniu.youjiamall.cn/ts-hm-ca.png" alt=""> -->
                    <img class="book-img" src="https://qiniu.youjiamall.cn/tslx22.png" alt="">
                    <img class="book-img-q" src="https://qiniu.youjiamall.cn/tslx33.png" alt="">
                  </div>

                  
                </div>
            </div>

            <!-- 为什么选择我们 -->
            <div class="main_prod">
                <pubBoxTit title="为什么选择我们" ltit="SELECT" paddingTop="51"></pubBoxTit>

                <div class="prod_mai">
                    <selMy></selMy>
                </div>
            </div>

            <!-- 我们的客户 -->
            <div class="main_prod main_curstom">
                <pubBoxTit title="我们的客户" ltit="CUSTOMER" paddingTop="45"></pubBoxTit>
                <div class="prod_mai">
                  <weUser arr-type="1"></weUser>
                </div>
            </div>

            <!-- 我们的伙伴 -->
            <div class="main_prod">
                <pubBoxTit title="我们的伙伴" ltit="PARTNER" paddingTop="49"></pubBoxTit>
                <div class="prod_mai">
                  <weUser arr-type="2"></weUser>
                </div>
            </div>

        </main>
      <pubFormInput></pubFormInput>
    </div>
</template>

<script>
import weUser from '@/components/hmComponent/weUser.vue'
import weProd from '@/components/hmComponent/weProd.vue'
import pubBoxTit from '@/components/pubBoxTit.vue'
import selMy from '@/components/hmComponent/selMy.vue'
import cuSerive from '@/components/hmComponent/cuSerive.vue'
import pubFormInput from "@/components/pubFormInput";

export default {
  name: 'Home',
  components: {weUser,selMy,weProd,cuSerive,pubBoxTit,pubFormInput},
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
  data() {
        return {
          bgurl:'https://qiniu.youjiamall.cn/yjpc/yjbg22.png',
          current:0,
        }
    },
    methods:{
      sonChange(val) {
        this.$parent.fooNav(val);
      },
      putUrl(index) {
        this.current = index;
        switch (index) {
          case 0:
            this.bgurl = 'https://qiniu.youjiamall.cn/yjpc/yjbg22.png'
            break;
          case 1:
            this.bgurl = 'https://qiniu.youjiamall.cn/yjpc/yjbg44.png'
            break;
          case 2:
            this.bgurl = 'https://qiniu.youjiamall.cn/yjpc/yjbg3.png'
            break;
          case 3:
            this.bgurl = 'https://qiniu.youjiamall.cn/bm-bk-bg.png'
            break;  
        }
      }
    }

}
</script>

<style>
.swiper {
  /*height: 524px;*/
  background: #F4F7FA;
}
.swiper img{
  width: 100%;
  /*object-fit: contain;*/
  /*object-fit: fill;*/
}


.home {
    width: 100%;
    height: 100%;
}


/*.banner {*/
/*    width: 100%;*/
/*    height: 524px;*/
/*    background: #F4F7FA;*/
/*}*/

/*.banner img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/


.prod_mai {
    min-height: 527px;
}
.weProd {
    width: 100%;
    /*height: 100%;*/
    height: 527px;
    display: flex;
    align-items: center;
}
.bgred {
  background: url("https://qiniu.youjiamall.cn/yjpc/yjbg2.jpg")no-repeat;
  background-size: 100% 100% !important;
}
.yjbgMini {
  position: absolute;
  top: -8px;
  right: 100px;
  display: flex;
}
.mi_left,
.mi_right{
  display: flex;
  flex-direction: column;
}
.yjbgMini .mi_left img{
  width: 242px;
  height: 153px;
  margin: 8px 0;
}
.yjbgMini .mi_left img:last-child{
  display: inline-block;
  margin-top: 30px;
}
.yjbgMini .mi_left {
  margin-top: 30px;
  margin-right: 40px;
}
.yjbgMini .mi_right {
  margin-top: 20px;
}
.yjbgMini .mi_right img {
  width: 341px;
  height: 170px;
  margin-bottom: 30px;
}
.main_curstom {
    background: #F4F7FA;
}

.min_dy {
  position: absolute;
  left: -380px;
  top: 40%;
  transform: translateY(-50%);
}
.min_dy img {
  width: 300px;
  height: 150px;
}
.dg_mini {
  position: absolute;
  /*top: 50%;*/
  bottom: 50px;
  right:200px;
}
.dg_mini img:first-child {
  position: absolute;
  right: 0;
  top: -130px;
}
.dg_mini img:last-child {
  display: block;
  width: 300px;
  height: 150px;
}


.book_mini .book-tips{
  width: 81%;
  position: absolute;
  left: 275px;
  top: 50px;
}
.book_mini .book-img {
  width: 260px;
  position: absolute;
  left: 26%;
  bottom:30px;
}
.book-img-q {
  width: 360px;
  position: absolute;
  left: 26%;
  bottom:230px;

}
</style>
