<template>
    <div class="home">
        <div class="box" @mouseout="out" @mouseover="over">
            <img v-for="(item, index) in list" v-show="listIndex === index" :key="index" :src="item" alt="" />
            <!-- <p class="left" @click="changePage(prevIndex)">&lt;</p> -->
            <ul class="box_rad">
                <li :class="{ color: index == listIndex }" v-for="(item, index) in list" @click="changePage(index)"
                    :key="index">
                     <img :src="item" alt="">
                </li>
            </ul>
            <!-- <p class="right" @click="changePage(nextIndex)">&gt;</p> -->
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            list: [
                "https://p0.meituan.net/travelcube/34b2b1aa5a5385b4d4ef5653af277132147543.jpg",
                "https://p0.meituan.net/travelcube/daf04919954cb9ae5736751fd006c0ec355533.jpg"
                // "https://ns-strategy.cdn.bcebos.com/ns-strategy/upload/fc_big_pic/part-00574-2088.jpg",

                // "https://img0.baidu.com/it/u=2171411284,1924893541&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1679936400&t=5d1f04529e979f6364e9ca326ed178ff",
                // "https://img0.baidu.com/it/u=2171411284,1924893541&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1679936400&t=5d1f04529e979f6364e9ca326ed178ff",
                // "https://img0.baidu.com/it/u=2171411284,1924893541&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1679936400&t=5d1f04529e979f6364e9ca326ed178ff",
                // "https://img0.baidu.com/it/u=2171411284,1924893541&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1679936400&t=5d1f04529e979f6364e9ca326ed178ff",
            ],
            listIndex: 0, //默认显示第几张图片
            timer: null, //定时器
        };
    },
    computed: {
        //上一张
        prevIndex() {
            if (this.listIndex == 0) {
                return this.list.length - 1;
            } else {
                return this.listIndex - 1;
            }
        },
        //下一张
        nextIndex() {
            if (this.listIndex == this.list.length - 1) {
                return 0;
            } else {
                return this.listIndex + 1;
            }
        },
    },
    methods: {
        changePage(index) {
            this.listIndex = index;
        },
        //移除
        out() {
            this.setTimer();
        },
        //移入
        over() {
            clearInterval(this.timer);
        },
        //1秒切图
        setTimer() {
            this.timer = setInterval(() => {
                this.listIndex++;
                if (this.listIndex == this.list.length) {
                    this.listIndex = 0;
                }
            }, 1000);
        },
    },
    created() {
        //定时器
        this.setTimer();
    },
    mounted() { },
};
</script>
<style scoped>
.home {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box {
    position: relative;
    width: 100%;
    height: 100%;


}
.box img {
    width: 100%;
    height: 624px;
    z-index: 100;
    object-fit: cover;
}

.box p {
    cursor: pointer;
    color: white;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
}

.box .left {
    position: absolute;
    top: 50%;
    left: 0;
}

.box .right {
    position: absolute;
    top: 50%;
    right: 0;
}

.box .box_rad {
    position: absolute;
    right: 120px;
    bottom: 12px;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 150px;
    height: 20px;
    /* top: 90%; */
    /* right: 35%; */
}

.box .color {
    background: #007858;
    /* color: red; */
}

.box li {
    cursor: pointer;
    width: 10px;
    height: 10px;
    background: #BABDC0;
    border-radius: 50%;
}
</style>
