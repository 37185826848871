<template>
    <div>
        <div v-if="type == 1" class="mai_tit" :style="'padding-top:' + paddingTop + 'px;'">
            <p style="color:#fff;border: 0;">{{ title }}</p>
            <span style="color: #fff; opacity: 0.36;">{{ ltit }}</span>
        </div>
        <div class="mai_tit" v-if="type == 0 && borderNone == 0 && !cakeTit" :style="'padding-top:' + paddingTop + 'px;'">
            <p>{{ title }}</p>
            <span>{{ ltit }}</span>
        </div>

      <div v-if="borderNone == 1" class="mai_tit" style="border: none" v-else :style="'padding-top:' + paddingTop + 'px;'">
        <p>{{ title }}</p>
        <span>{{ ltit }}</span>
      </div>

      <!--cake页面 使用流程-->
      <div  v-if="cakeTit" class="mai_tit" :style="'padding-top:' + paddingTop + 'px;'">
        <p>{{ title }}</p>
        <span style="color: #ddd">{{ ltit }}</span>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        ltit: {
            type: String,
            default: ''
        },
        paddingTop: {
            type: Number,
            default: 50
        },
        type:{
          type:Number,
          default:0
        },
        borderNone:{
          type:Number,
          default:0
        },
        cakeTit:{
          type:Boolean,
          default:false
      }
    },
    data() {
        return {
        };
    },

    methods: {}
}

</script>
<style scoped>
.mai_tit {
    width: 100%;
    border-bottom: 1px solid #BDD6C3;
    box-sizing: border-box;
    padding-top: 64px;
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding-left: 121px;
    box-sizing: border-box;
}

.mai_tit p {
    box-sizing: border-box;
    padding-bottom: 28px;
    border-bottom: 2px solid #007858;
    margin-right: 12px;
}

.mai_tit span {
    font-size: 30px;
    font-family: Arial;
    font-weight: 400;
    color: #EEEEEE;
}
</style>
