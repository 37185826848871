<template>
  <div class="swiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">

        <div class="swiper-slide" v-for="item in banners">
          <img :src="item" alt="">
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name:'About',
  data(){
    return {
      banners:[
          "https://qiniu.youjiamall.cn/yjpc/yjbanner1.png",
          "https://qiniu.youjiamall.cn/yjpc/yjbanner2.png",
      ]
    }
  },
  mounted() {
    new Swiper ('.swiper-container', {
      loop: true,
      // 如果需要分页器
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
      //如果需要自动切换海报
      autoplay: {
        delay: 3000,//时间 毫秒
        disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      },
    })
  }
}
</script>

<style scoped>


.swiper-container{
  height: 100%;
  width: 100%;
}

.swiper-slide{
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
