<template>
  <div>
    <div class="oneuser weUser " v-if="arrType == 1">
      <!--      我们的客户-->
      <ul class="user_list scaleList" >
        <li v-for="(item,index) in khArr" :key="index"  @mousemove="mouseChange(index)" :class="{activeLi:current === index}">
          <img :src="item" alt="">
        </li>
      </ul>
    </div>
    <div class="weUser lastUser"  v-if="arrType == 2">
      <!--      我们的伙伴-->
      <ul class="user_list scale">
        <li v-for="(item,index) in hzArr" :key="index" @mousemove="mouseChange(index)" :class="{activeLi:current === index}">
          <img :src="item" alt="">
        </li>
      </ul>
    </div>

    <div class="weUser lastUser"  v-if="arrType == 3">
      <!--  优质出版社-->
      <ul class="cbs">
        <li v-for="(item,index) in cbsArr" :key="index" @mousemove="mouseChange(index)" :class="{activeLi:current === index}">
          <img :src="item" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'weUser',
  props:{
    arrType:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      list:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
      current:0,
      khArr:[
          "https://qiniu.youjiamall.cn/yjpc/kehu_1%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_2%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_3%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_4%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_5%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_6%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_7%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_8%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_9%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_10%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_11%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_12%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_13%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_14%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_15%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_16%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_17%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_18%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_19%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_20%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_21%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_22%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_23%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_24%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/kehu_25%402x.png",
      ],
      hzArr:[
          "https://qiniu.youjiamall.cn/yjpc/df_logo.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_03%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_04%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_05%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_06%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_07%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_08%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_09%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_10%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_11%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_12%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_13%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_14%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_15%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_16%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_17%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_18%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_19%402x.png",
          "https://qiniu.youjiamall.cn/yjpc/hezuo_20@02x.png",
      ],
      cbsArr:[
        "https://qiniu.youjiamall.cn/xcbs1111.png",
        "https://qiniu.youjiamall.cn/xxcbs2.png",
        "https://qiniu.youjiamall.cn/xcbs3333.png", 
        "https://qiniu.youjiamall.cn/xcbs4444.png",

      ]
    }
  },
  methods:{
    mouseChange(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped>
.weUser {
  position: relative;
  width: 100%;
}
  .user_list {
    top: 80px;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    left: 4.5%;
    width: 95%;
  }
  .user_list li {
    width: 258px;
    height: 94px;
    border-top: 1px solid #DEDEDE;
    border-right: 1px solid #DEDEDE;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user_list li:nth-child(1),
  .user_list li:nth-child(6),
  .user_list li:nth-child(11),
  .user_list li:nth-child(16),
  .user_list li:nth-child(21){
    border-left: 1px solid #DEDEDE;
  }
  .user_list li:nth-child(21),
  .user_list li:nth-child(22),
  .user_list li:nth-child(23),
  .user_list li:nth-child(24),
  .user_list li:nth-child(25) {
    border-bottom: 1px solid #DEDEDE;
  }

  .scale img{
    transform: scale(0.6);
  }
  .scale li:nth-child(15),
  .scale li:nth-child(16),
  .scale li:nth-child(17),
  .scale li:nth-child(18),
  .scale li:nth-child(19),
  .scale li:nth-child(20){
    border-bottom: 1px solid #DEDEDE;
  }
.scale li:nth-child(20){
  border-top: 0;
}

.scaleList img{
  transform: scale(0.5);
}
.scaleList li:nth-child(21) img,
.scaleList li:nth-child(23) img,
.scaleList li:nth-child(24) img,
.scaleList li:nth-child(25) img {
  transform: scale(0.8);
}
.scaleList li:nth-child(22) img {
  transform: scale(.9);
}

.scaleList li:nth-child(15) img,
.scaleList li:nth-child(17) img,
.scaleList li:nth-child(18) img,
.scaleList li:nth-child(19) img{
  transform: scale(0.4);
}
.scaleList li:nth-child(16) img {
  transform: scale(0.35);
}
.oneuser {
  height: 630px;
}
.lastUser {
  /*height: 656px;*/
}

@media (max-width: 1440px) {
  .scaleList img{
    transform: scale(0.3);
  }
  .scaleList li:nth-child(15) img,
  .scaleList li:nth-child(17) img,
  .scaleList li:nth-child(18) img,
  .scaleList li:nth-child(19) img{
    transform: scale(0.3);
  }
  .scaleList li:nth-child(16) img {
    transform: scale(0.25);
  }
  .scaleList li:nth-child(21) img,
  .scaleList li:nth-child(23) img,
  .scaleList li:nth-child(24) img,
  .scaleList li:nth-child(25) img{
    transform: scale(0.5);
  }


  .scale img{
    transform: scale(0.4);
  }
}
.cbs {
  top: 50px;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    left: 0%;
    width: 100%;
}
.cbs li {
  height: 122px;
  margin-bottom: 12px;
}
.cbs li img {
  /* width: 286px; */
  height: 122px;
  margin-left: 10px;
}
</style>
