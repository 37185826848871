<template>
  <div class="main_form">
    <div class="form_text">
      <span>福利采购WELFARE</span>
      <p>用心服务每一个客户</p>
    </div>

    <div class="form_input">
      <form ref="form" @submit.prevent="sendEmail">
        <input type="text"  v-model="params.companyName"  name="CompanyName" placeholder="请输入您公司简称">
        <input type="text"  v-model="params.username" name="name" placeholder="请输入您的姓名">
        <input type="text"  v-model="params.phone" maxlength="11" name="Phone" placeholder="请输入您的手机号">
              <div class="form_code">
                <input type="text" v-model="identifyCode" placeholder="请输入验证码">
                <VerifyCode contentWidth="124" contentHeight="42" class="pd-t-6" v-model:changeCode="identifyCode"  />
              </div>
        <input v-if="!isDbtn" type="submit" class="form_sub" value="立即预约">
      </form>
      <button v-if="isDbtn"  disabled class="form_sub disable">立即预约</button>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';
import VerifyCode from "@/components/VerifyCode";
export default {
  name: "pubFormInput",
  components:{VerifyCode},
  data() {
    return {
      identifyCode:"",
      params:{
        companyName:"",
        username:"",
        phone:""
      },
      isDbtn:false
    }
  },
  methods:{
    sendEmail() {
      if(this.params.companyName == "" || this.params.username == "" || this.params.phone == "" ) {
        alert('为更好的为您服务，请您完善相关信息~')
        return false;
      }
      if(this.params.phone.length != 11) {
        alert('您的手机号码格式有误！');
        return false;
      }
      if(this.identifyCode.toUpperCase() != this.$children[0].identifyCode.toUpperCase()) {
        alert('您的验证码有误~')
        return false;
      }
      this.isDbtn = true;
      emailjs.sendForm('service_ran5c65', 'template_0e52xke', this.$refs.form, 'cqFIomNLTWlpmUMFQ')
          .then((result) => {
            this.isDbtn = false;
            alert('预约成功！'+'\n'+'我们会在24小时内给您回电')
            this.identifyCode = '';
          }, (error) => {
            this.isDbtn = false;
            this.identifyCode = '';
            alert(error.text)
          });
    }
  }
}
</script>

<style scoped>
.main_form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  /*background: #007858;*/
  padding: 50px 120px 40px;
  background: url("https://qiniu.youjiamall.cn/yjpc/yjbg.png")no-repeat;
  background-size: 100% 100%;

}

.form_input input {
  width: 260px;
  display: inline-block;
  background: #FFFFFF;
  border-radius: 7px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-bottom: 13px;
  border: 0;
  outline: none;
  padding-left: 8px;
  box-sizing: border-box;
}

.form_input .form_sub {
  width: 259px;
  height: 36px;
  background: #FFF100;
  border-radius: 18px;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #007656;
}

.form_input p {
  color: #fff;
  margin-bottom: 13px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.form_input form {
  display:flex;
  flex-direction: column;
}

.form_code {
  display: flex;
  align-items: center;
}

.form_code input {
  width: 148px;
  margin-right: 10px;
}

.form_text {
  margin-top: 100px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #D4FFF0;
  width: 220px;

}

.form_text span {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}

.form_text p {
  margin-top: 16px;
  border-bottom: 1px solid #fff;
  padding-bottom: 7px;
  box-sizing: border-box;
}

.disable {
}
</style>
