<template>
    <div class="sel_my">
        <ul>
            <li v-for="(item,index) in selArr" :class="{mouseActive:current === index}" :key="index" @mousemove="mouseChange(index)">
                <p class="img_box">
                  <img v-if="current === index" :src="item.selImgurl" alt="">
                  <img v-else :src="item.imgurl" alt="">
                </p>
                <p>{{ item.selTit }}</p>
                <span class="xian"></span>
                <span class="selspan">{{ item.titTop }}<br>{{ item.titBtm }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'selMy',
    data() {
        return {
            selArr:[{
                selTit:'贴心',
                titTop:'从方案制定到售后',
                titBtm:'一路陪伴',
                imgurl:require('@/assets/img/tx_1.png'),
                selImgurl:require("@/assets/img/tx.png")
            },{
                selTit:'高效',
                titTop:'各部门实时在线',
                titBtm:'第一时间响应',
                imgurl:require('@/assets/img/gx_1.png'),
                selImgurl:require("@/assets/img/gx.png")

            },{
                selTit:'服务',
                titTop:'7*12小时',
                titBtm:'人工客服',
                imgurl:require('@/assets/img/fw_1.png'),
              selImgurl:require("@/assets/img/fw.png")

            },{
                selTit:'省钱',
                titTop:'以更优惠的价格',
                titBtm:'服务客户',
                imgurl:require('@/assets/img/sq_1.png'),
              selImgurl:require("@/assets/img/sq.png")

            },{
                selTit:'专业',
                titTop:'10余年行业深耕经验',
                // titBtm:'从心选品',
                imgurl:require('@/assets/img/zy_1.png'),
              selImgurl:require("@/assets/img/zy.png")

            },{
                selTit:'优质',
                titTop:'专业运营团队',
                titBtm:'从心选品',
                imgurl:require('@/assets/img/yz_1.png'),
              selImgurl:require("@/assets/img/yz.png")


            },{
                selTit:'定制',
                titTop:'根据不同企业、场景',
                titBtm:'个性化定制',
                imgurl:require('@/assets/img/dz_1.png'),
              selImgurl:require("@/assets/img/dz.png")

            },{
                selTit:'省心',
                titTop:'企业福利一站式解决方案',
                imgurl:require('@/assets/img/sx_1.png'),
              selImgurl:require("@/assets/img/sx.png")

            }],
            current:0
        }
    },
    methods:{
      mouseChange(index) {
            this.current = index;
        },
    }
}
</script>

<style scoped>
.sel_my {
    box-sizing: border-box;
    padding: 37px 0 54px 121px;

}

.sel_my ul {
    display: flex;
    flex-wrap: wrap;
}

.sel_my li {
    padding-top: 30px;
    /* padding-bottom: 35px; */
    box-sizing: border-box;
    margin-right: 45px;
    margin-bottom: 34px;
    width: 266px;
    height: 288px;
    background: #FFFFFF;
    box-shadow: 0px 5px 22px 0px rgba(0, 120, 88, 0.08);
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    border: 1.5px solid rgba(0,0,0,0);
    box-sizing: border-box;
}

.sel_my li p {
    padding-bottom: 18px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}


.sel_my img {
    margin-bottom: 27px;
}

.sel_my .xian {
    margin: auto;
    margin-bottom: 17px;
    display: block;
    width: 28px;
    height: 2px;
    background: #DFDFDF;
}
.img_box {
  margin: auto;
  width: 125px;
  height: 125px;
}

.mouseActive {
  color: #007858 !important;
  border: 1.5px solid #007858 !important;
  box-sizing: border-box !important;
}
.mouseActive .xian {
  background: #007858;
}

.selspan {
  line-height: 20px;
}
</style>
