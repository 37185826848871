import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible'
import router from './router/index'
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo)

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
//跳转页面后回到顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
