<template>
    <div>
        <!-- 底部联系方式 -->
        <div class="main_bottom">

          <div class="btm_mai">
                <ul class="btm_mai_left">
                    <li><img src="@/assets/img/logo.png" alt=""></li>
                    <li>服务热线：400-700-1314</li>
                    <li>服务时间：09:00-21:00</li>
                    <li>商务合作：bd@youjiahuipin.com</li>
                    <li>公司地址：北京市朝阳区联信大厦A座6层</li>
                </ul>

                <div class="btm_mai_rwarp">
                    <div class="btm_mai_centet">
                        <div style="margin-right: 20px;">
                            <p>优加惠品公众号</p>
                            <img src="@/assets/img/gzh_code.png" alt="">
                        </div>
                        <div>
                            <p>优加福利小程序</p>
                          <img src="@/assets/img/xcx_code.png" alt="">
                        </div>
                    </div>

                    <div class="btm_mai_right">
                        <ul>
                            <li class="lis_title">企业福利</li>
                            <li v-for="(item,index) in selectArr" :key="index" @click="navClick(item)">{{item.name}}</li>
                        </ul>
                        <ul>
                            <li class="lis_title">关于我们</li>
                            <li v-for="(item,index) in lastArr" :key="index" @click="navClick(item)">
                              {{item.name}}
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
            <div class="btm_tips">
              <p style="margin-bottom: 4px;">版权所有 ©2022 北京优加惠品科技服务有限公司 &nbsp;&nbsp;<a class="bah" href="https://beian.miit.gov.cn/" target="_blank">{{baCode}}</a></p>
<!--              <p style="margin-top: 10px;"></p>-->
              <p class="tips_top" >
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/zyjk.png">职业健康安全管理体系认证证书</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/xxaq.png">信息安全管理体系认证证书</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/gltx.png">环境管理体系认证证书</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/zlgl.png">质量管理体系认证证书</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/ysxx.png">隐私信息管理体系认证证书</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/shfw.png">售后服务认证证书</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/zzdx.png">增值电信业务经营许可证</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/zpdj.png">作品登记证书</a>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/ylqx.png">第二类医疗器械经营备案凭证</a> <span>|</span>
                <a target="_blank" href="https://qiniu.youjiamall.cn/yjpc/spjy.png">食品经营许可证</a>
              </p>
            </div>
        </div>
    </div>
</template>


<script>
import VerifyCode from '@/components/VerifyCode.vue'
export default {
    components:{VerifyCode},
    mounted() {
      this.url = window.location.href;
      if(this.url.includes('youjiamall.cn')) {
        this.baCode = '京ICP备19036066号-3'
      }
    },
  data() {
        return {
            url:'',
            identifyCode: 0,
            baCode:'京ICP备19036066号-1',
            pathname:'',
            selectArr:[
              {
                name:'电影福利',
                path:'/welfare',
              },{
                name:'蛋糕福利',
                path:'/cake',
              },{
              name:'图书福利',
              path:'/book',
            },{
              name:'节日慰问',
              path:'/holiday',
            },{
              name:'企业活动',
              path:'/activity',
            }],
          lastArr:[{
              name:'品牌介绍',
              path:'/brandInt'
          },{
            name:'联系我们',
            path:'/aboutUs'
          }]
        }
    },
    methods:{
      navClick(item) {
        this.$router.push(item.path);
        this.$emit('fooNav',item.path); 
      }
    }
}
</script>

<style>

.btm_mai {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    display: flex;
    padding: 40px 0 40px 120px;
    box-sizing: border-box;
    justify-content: space-between;
}

.btm_mai li {
    margin-bottom: 10px;
    cursor: pointer;
}

.btm_mai_left li img{
  width: 44px;
  height: 44px;
}

.btm_mai_rwarp {
    display: flex;
    padding-right: 200px;
    box-sizing: border-box;
}

.btm_mai_centet {
    display: flex;
    margin: 0 100px 0 93px;
}

.btm_mai_centet p {
    margin-bottom: 26px;
    margin-right: 18px;
}

.btm_mai_centet img {
    width: 103px;
    height: 103px;
}

.btm_mai_right {
    display: flex;
  text-align: center;
}
.btm_mai_right a {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.btm_mai_right ul {
    margin-right: 37px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.btm_mai_right .lis_title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.btm_tips {
  border-top: 1px solid #eee;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.btm_tips a{
  display: inline-block;
  /*margin: 0 6px;*/
  color: #999;
}
.btm_tips a:hover {
  color: #000;
}
.tips_top {
  margin: auto;
  width: 80%;
  line-height: 20px;
}
.tips_top span {
  margin: 0 5px;
}
.btm_tips .bah {
  color: rgb(0,0,238);
  cursor: pointer;
  text-decoration: underline;
}
</style>
